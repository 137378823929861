<template>
  <div>
    <b-row>
      <b-col
        v-for="(dashboard, index) in dashboards"
        :key="index"
        :xl="3"
        :lg="4"
        :md="6"
        :sm="12"
      >
        <b-card

          class="custom-border-top-1px"
        >
          <b-card-header class="p-0">
            <h4 class="text-primary font-weight-bolder">
              {{ dashboard.name }}
            </h4>
          </b-card-header>
          <hr>
          <div class="d-flex justify-content-end">
            <b-button
              variant="outline-primary"
              @click="goToDetal(dashboard.id)"
            >
              Xem chi tiết
              <feather-icon icon="ArrowRightIcon" />
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import store from '@/store'
import reportStoreModule from '@/views/report/reportStoreModule'
import { onUnmounted } from '@vue/composition-api'
import {
  BCard, BCardHeader, BRow, BCol, BButton,
} from 'bootstrap-vue'

import useReportGeneral from './useReportGeneral'

export default {
  name: 'ReportGeneral',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BButton,
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })
    const { dashboards, fetchDashboards } = useReportGeneral()
    return {
      dashboards,
      fetchDashboards,
    }
  },
  created() {
    this.fetchDashboards()
  },
  methods: {
    goToDetal(id) {
      this.$router.push({
        name: 'report-general-detail',
        params: {
          id,
        },
      })
    },
  },
}
</script>
