import { ref } from '@vue/composition-api'
import store from '@/store'
import http from '@/global/http'

export default function useReportGeneral() {
  const dashboards = ref([])
  const dashboardDetail = ref({})

  const fetchDashboards = () => http.handle(store.dispatch('report/fetchReportGeneral'), res => {
    dashboards.value = res.data || []
  })

  const createEmbed = dashboardId => http.handle(store.dispatch('report/createEmbed', { dashboard_id: dashboardId }), res => {
    dashboardDetail.value = res.data || {}
  })

  return {
    dashboards,
    dashboardDetail,
    fetchDashboards,
    createEmbed,
  }
}
